import { getConfig } from "utilities/config";

import isEmpty from "utilities/isEmpty";
import axios from "axios";
import { useCallback, useState } from "react";

import _debounce from 'lodash/debounce';
import { BaseSelectFromApi } from "../select/base/SelectFromApi";
import { alertError } from "../functions/alerts";
import { getIconComponents } from "../select/constants/IconConstants";

export function HomeAddressPicker(props) {
    const { onChange, value: valueProp, ...rest } = props;
    const config = getConfig();
    const [showOptions, setShowOptions] = useState(false);
    const [showNoData, setShowNoData] = useState(false);
    const [value, setValue] = useState(valueProp);
    const [loading, setLoading] = useState(null);

    const loadOptions = (inputValue, callback) => {
        if (!isEmpty(inputValue) && inputValue.length >= 3) {
            setLoading(true)
            try {
                axios
                    .get(
                        `${config.NEXT_PUBLIC_BASE_URL}/search/locations?search==${inputValue}`,
                    )
                    .then(({ data }) => {
                        setLoading(false);
                        let options = data.map((prediction) => {
                            return {
                                value: { id: prediction.id, locationSourceId: prediction.locationSourceId }, label: prediction.locationName
                            }
                        });
                        callback(options);
                    });
            } catch (error) {
                //todo: implement error handling here
                callback(null);
                setLoading(false);
            }
        } else {
            callback(null);
            setLoading(false);
        }
    };

    const handleChange = (value) => {
        if (!isEmpty(value?.value)) {
            setValue(value);
            onChange(value);
        }
        else {
            setValue(null);
            onChange(null);
        }
    }

    const wait = 500;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceLoadOptions = useCallback(_debounce(loadOptions, wait), [showOptions]);

    const { ClearIndicator, MultiValueRemove } = getIconComponents();

    const handleInputChange = (typedOption) => {
        if (typedOption.length >= 3) {
            setShowOptions(true);
        }
        else {
            setShowOptions(false);
        }

        if (typedOption.length >= 4) {
            setShowNoData(true);
        }
        else {
            setShowNoData(false);
        }
    }

    const getControlStyles = () => {
        return {
            base: `w-full peer focus:outline-0 focus:border-0 bg-base-100  text-base-content text-xl lg:text-2xl`,
            focus: `border-0 outline-0 `,
            nonFocus: `border-0 outline-0`,
        };
    }

    const getPlaceholderStyles = () => {
        return `w-full peer focus:outline-0 focus:border-0 bg-base-100 text-base-content/60 text-base-content text-xl xl:text-2xl h-[32px] `;
    }

    // export const selectInputStyles = "text-base-content/100 pl-2 py-0.5 ";
    const getSelectInputStyles = () => {
        return `text-base-content/100 `;
    }

    // export const valueContainerStyles = "p-1 gap-1";
    const getValueContainerStyles = () => {
        return `p-0 gap-0`;
    }

    // export const singleValueStyles = "ml-1 text-[0.875rem]";
    const getSingleValueStyles = () => {
        return `text-xl xl:text-2xl`;
    }

    const getSelectLoadingControl = () => {
        return <div className="text-xl xl:text-2xl h-[32px] text-base-content/60">Loading...</div>;
    }

    return (
        <BaseSelectFromApi
            isClearable={true}
            components={{
                DropdownIndicator: null,
                ClearIndicator,
                MultiValueRemove,
            }}
            controlStylesOverride={getControlStyles()}
            placeholderStylesOverride={getPlaceholderStyles()}
            selectInputStylesOverride={getSelectInputStyles()}
            valueContainerStylesOverride={getValueContainerStyles()}
            singleValueStylesOverride={getSingleValueStyles()}
            selectLoadingControlOverride={getSelectLoadingControl()}
            cacheOptions
            loadOptions={showOptions ? debounceLoadOptions : null}
            defaultOptions
            onInputChange={handleInputChange}
            onChange={handleChange}
            value={value}
            noOptionsMessage={() => showNoData ? 'No addresses found.' : "Please type at least 4 characters..."}
            isMulti={false}
            {...rest}
            isLoading={loading}


        />

    );
}

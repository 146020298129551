"use client"
import React from 'react';
import {
  Navbar,
  Typography,
  IconButton,
  Button,
  Drawer,
} from '@material-tailwind/react';

import { UserIcon, HomeModernIcon } from '@heroicons/react/24/solid';
import { useDispatch, useSelector } from 'react-redux';
import { setFormIndex, toggleDialogOpen } from '../../features/auth/authSlice';

import { useAuthentication } from '../../hooks/useAuthentication';
import { SidebarDefault } from './SidebarDefault';
import { AuthDialog } from '../../features/auth/AuthDialog';
import Link from 'next/link'
import isEmpty from 'utilities/isEmpty';
import Image from 'next/image';
import { AuthForms, lightModeTheme } from 'utilities/constants';

export const NavbarDefault = (props) => {
  const theme = useSelector((state) => state.home.theme);
  const [top, setTop] = React.useState(true);
  const [openNav, setOpenNav] = React.useState(false);
  const closeDrawer = () => setOpenNav(false);

  const { isAuthenticated } = useAuthentication();
  const dispatch = useDispatch();
  const handleOpen = () => {
    dispatch(setFormIndex(AuthForms.LOGIN));
    dispatch(toggleDialogOpen());
  }

  const sticky = props.sticky ?? true;
  const fullWidth = props.fullWidth ?? false;
  const disableShadow = props.disableShadow ?? false;
  const className = props.className;

  React.useEffect(() => {
    window.addEventListener(
      'resize',
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, [props]);

  React.useEffect(() => {
    const scrollHandler = () => {
      window.scrollY > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  function navList() {
    return (
      <ul className="flex gap-2 mb-0 mt-0 flex-row items-center gap-6">
        {!isAuthenticated && (
          <Typography as="li" className="font-normal hidden md:block">
            <Button
              variant={'text'}
              size={'sm'}
              className="font-medium normal-case  flex items-center text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10"
              onClick={handleOpen}>
              <UserIcon className="h-5 w-5 mr-1" /> Login / Sign up
            </Button>
          </Typography>
        )}
        {/* <Typography as="li" className="font-normal hidden md:block">
          <Button
            variant={'text'}
            size={'sm'}
            className="font-medium normal-case flex items-center  text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10"
          // onClick={() => navigate("/create-venue")}
          >
            <HomeModernIcon className="h-5 w-5 mr-1" /> List your venue
          </Button>
        </Typography> */}
        <Typography as="li" className="font-normal text-base-content/100">
          <IconButton
            variant="text"
            className={
              isAuthenticated ? 'inline-block' : 'inline-block md:hidden'
            }
            ripple={false}
            onClick={() => setOpenNav(!openNav)}>
            {openNav ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6 text-base-content/100"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-base-content/100"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </IconButton>
        </Typography>
      </ul>
    );
  }

  return (
    <React.Fragment>
      <Navbar
        className={`${sticky ? 'lg:sticky' : ''
          } top-0 z-[999] mx-auto py-2 lg:py-4 bg-base-100 border-t-0 border-l-0 border-r-0  border-b-0 backdrop-blur-none backdrop-saturate-0 bg-opacity-100 ${(top || disableShadow) ? `shadow-none` : `lg:shadow-md`
          } ${!isEmpty(className) && className} h-[56px] lg:h-[72px]`}
        fullWidth>
        <div
          className={`${!fullWidth ? 'lg:container' : ''
            } mx-auto flex items-center justify-between text-base-content/100`}>
          <Link
            className="mr-4 cursor-pointer font-medium text-base-content/100"
            href="/">
            {theme === lightModeTheme ? <Image src="/logo_wide_light.png" height={40} width={200} alt="VenueConfirm Logo" /> : <Image src="/logo_wide_dark.png" height={40} width={200} alt="VenueConfirm Logo" />}

          </Link>
          {!isEmpty(props.navListOverride) ? <props.navListOverride /> : <div>{navList()}</div>}
        </div>
      </Navbar>
      <Drawer
        open={openNav}
        onClose={closeDrawer}
        placement="right"
        className={'overflow-y-auto bg-base-100'}>
        <SidebarDefault setOpenNav={setOpenNav} />
      </Drawer>

      <AuthDialog />
    </React.Fragment>
  );
}

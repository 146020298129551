"use client"

import Image from 'next/image';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import { lightModeTheme } from 'utilities/constants';

export function FullFooter(props) {
    const theme = useSelector((state) => state.home.theme);

    return (
        <div className={`${theme === lightModeTheme ? "bg-base-content text-base-200" : "bg-base-100 text-base-content"} w-full min-h-[150px] pt-5 pb-16 mt-auto`}>
            <div className="lg:container mx-auto px-2">
                <div className='mb-4'>
                    <Link
                        // className="mr-4 cursor-pointer font-medium text-base-content/100"
                        href="/">
                        <Image src="/logo_wide_white.png" height={40} width={200} alt="VenueConfirm Logo" />
                    </Link>
                </div>
                <div className="text-sm">
                    © {new Date().getFullYear()} VenueConfirm. All rights reserved.
                </div>
            </div>
        </div>
    );
}
